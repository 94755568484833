<template>
  <div>
    <img :style="{width: width, height: height}" @click="dialogVisible = true" :src="$route.params.url+url" :onerror="common.imgOnError"/>
    <!-- <div class="crop-demo-btn label" @click="dialogVisible = true">选择图片</div> -->
    <el-dialog  :visible.sync="dialogVisible" @open="dialogOpen" width="80%" top="5vh" :append-to-body="true" :modal-append-to-body="true">
      <span slot="title">
		  <div class="title">选择图片</div>
		  <div class="path">{{curPath}}</div>
		  <el-button :disabled="!curPath" size="mini" type="warning" plain @click="back">返回</el-button>
		  <el-button size="mini" type="warning" plain @click="addDirClick">添加文件夹</el-button>
		  <el-button size="mini" type="primary" plain @click="$refs.file.click()">上传文件</el-button>
		  <el-button size="mini" type="danger" plain @click="delClick">删除</el-button>
		  <input type="file" ref="file" class="upInput" accept="image/*" @change="fileChange"/>
		</span>
	  <dir>
		  
          <ul class="imgList">
			  <li class="imgItem" v-for="(item, index) in files" :key="index" :class="{active:selected === item}">
				<img class="imgItemI" v-if="!item.isdir" :src="$route.params.url+item.path" @click="selected = item" @dblclick="confirm(item)"/>
				<div class="imgItemI el-icon-folder" v-if="item.isdir" @click="selected = item" @dblclick="dirClick(item.name)"></div>
				<span>{{item.name}}</span>
				<i class="el-icon-success" v-if="selected === item && !item.isdir"></i>
			  </li>
          </ul>
      </dir>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button @click="confirm()" type="primary" :disabled="selected&&selected.isdir">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
		curPath: '',
      selected: null,
      dialogVisible: false,
      files: [],
      opt: {}
    }
  },
  created: function () {
  },
  methods: {
    dialogOpen () {
      this.getMyImgs('')
    },
    getMyImgs () {
      const data = { module: 'RemoteFile', action: 'GetFiles',prms: {sysId: this.$route.params.id,path: this.curPath} }
      this.common.request(data, this.getFilesCb.bind(this))
    },
    getFilesCb (r) {
		r.data.sort(function (a, b) {
		    return  b.isdir - a.isdir
		})
      this.files = r.data
    },
	back(){
		this.curPath = this.curPath.substring(0,this.curPath.lastIndexOf('/'))
		this.getMyImgs()
	},
	dirClick(dir){
		this.curPath += '/'+dir
		this.getMyImgs()
	},
	addDirClick(){
		this.$prompt('请输入名称', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',}).then(({ value }) => {
		    const data = { module: 'RemoteFile', action: 'AddDir',prms: {sysId: this.$route.params.id,path: this.curPath + "/"+value} }
		    this.common.request(data, this.addDirCb.bind(this,value))
		})
	},
	addDirCb(dir,r){
		console.log(dir)
		this.getMyImgs()
	},
	fileChange(e){
		let that = this
		try {
			var file = e.target.files[0]
			if (!/image\/\w+/.test(file.type)) {
				alert("请确保文件为图像类型");
				return false;
			}
			var ext = file.name.substr(file.name.lastIndexOf('.') + 1);
			var reader = new FileReader();
			reader.onload = function () {
				let base64 = this.result.substr(this.result.indexOf(',') + 1)
				const data = { module: 'RemoteFile', action: 'UploadImg',prms: {sysId: that.$route.params.id,path: that.curPath,ext: ext,img: base64} }
				that.common.request(data, that.addDirCb.bind(that))
			}
			reader.readAsDataURL(file);
			e.srcElement.value = ""
		} catch (e) {
			alert('图片转Base64出错啦！' + e.toString())
		}
	},
	uploadCb(r){
		this.getMyImgs()
	},
	delClick(){
		this.$confirm('确定删除选择文件吗', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'}).then(() => {
		    const data = { module: 'RemoteFile', action: 'DeleteDir',prms: {sysId: this.$route.params.id,path: this.curPath +'/'+ this.selected.name } }
		    this.common.request(data, this.delCb.bind(this))
		})
	},
	delCb(r){
		this.getMyImgs()
	},
    confirm (item) {
		if(item) this.selected = item
		this.$emit('setUrl', this.selected.path)
		this.dialogVisible = false
    }
  },
  model: {
    prop: 'url',
    event: 'setUrl'
  },
  props: {
    url: '',
    width: { default: '50px' },
    height: { default: '50px' }
  }
}
</script>
<style lang='scss' scoped>
  .el-dialog__body {padding: 0px 20px;}
  .el-container{
    height: 300px;
  }
  .el-aside{
    border-right: 1px solid #0D3349;
    padding-right: 5px;
  }
  .el-main{
    padding: 0;
  }
  .label{
    cursor: pointer;
  }
  .menu{
    float: left;
    font-size: 14px;
    line-height: 25px;
    cursor: pointer;
    width: 80px;
  }
  .imgItem{
    width: 100px;
    height: 100px;
    float: left;
    margin: 10px;
    position: relative;
    border: 1px solid white;
	text-align: center;
    i {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 38px;
      color: #2494f2;
    }
  }
  .imgItemI{
	  width: 80px;
      height: 80px;
      margin: auto;
      display: block;
	  font-size: 70px;
	  color: orange;
  }
  .imgAdd{
    width: 80px;
    height: 80px;
    font-size: 78px;
    font-weight: 1400;
    font-style: normal;
    color: #2494f2;
    border: 1px solid #2494f2;
  }
  .menuActve {
    background: #fff;
    color: #2494f2;
    i {
      color: #2494f2;
    }
  }
  .imgList {
    float: left;
    width: 100%;
    height: calc(90vh - 200px);
    overflow: auto;
    li.active {
      border: 1px solid #2494f2;
    }
  }
  .addType{
    bottom: 0px;
  }
  .upInput{
    display: none;
  }
  .title{
	  text-align: center;
	  font-size: 24px;
	  background-color: bisque;
  }
  .path{
	  height: 25px;
	  font-size: 24px;
	  border: 1px solid bisque;
  }
</style>
