<template>
  <el-tabs type="border-card" v-model="active" @tab-click="tabClick">
    <el-tab-pane label="基础" name="基础">
		<el-descriptions title="小程序" :column="2" border>
		  <el-descriptions-item label="PC首页">
			  <selectorPage v-model="cfg.homePc"></selectorPage>
		  </el-descriptions-item>
			
			<el-descriptions-item label="手机首页">
				<selectorPage v-model="cfg.homeM"></selectorPage>
			</el-descriptions-item>
			<el-descriptions-item label="底部导航">
				<el-radio-group v-if="cfg.bm" v-model="cfg.bm.enable" size="mini">
				  <el-radio-button :label="true">启用</el-radio-button>
				  <el-radio-button :label="false">禁用</el-radio-button>
				</el-radio-group>
			</el-descriptions-item>
			
			<el-descriptions-item label="引导页">
				<el-radio-group v-model="cfg.prePage.enable" size="mini">
				  <el-radio-button :label="true">启用</el-radio-button>
				  <el-radio-button :label="false">禁用</el-radio-button>
				</el-radio-group>
			</el-descriptions-item>
			<el-descriptions-item v-if="cfg.prePage.enable" label="引导页类型">
				<el-radio-group v-model="cfg.prePage.type" size="mini">
				  <el-radio-button label="text">文字</el-radio-button>
				  <el-radio-button label="img">图片</el-radio-button>
				  <el-radio-button label="video">视频</el-radio-button>
				</el-radio-group>
			</el-descriptions-item>
			<el-descriptions-item v-if="cfg.prePage.enable" label="引导页内容">
				<el-input v-if="cfg.prePage.type=='text'" v-model="cfg.prePage.text"></el-input>
				<selectorImg v-if="cfg.prePage.type=='img'" v-model="cfg.prePage.img"></selectorImg>
				<selectorImg v-if="cfg.prePage.type=='video'" v-model="cfg.prePage.video"></selectorImg>
			</el-descriptions-item>
			<el-descriptions-item v-if="cfg.bm.enable" label="底部导航预览" :span="2">
				<bm v-if="cfg.bm" :com="cfg.bm"></bm>
			</el-descriptions-item>
			<el-descriptions-item v-if="cfg.bm.enable" label="底部导航编辑" :span="2">
				<bmEditor v-if="cfg.bm" :com="cfg.bm"></bmEditor>
			</el-descriptions-item>
		</el-descriptions>
		<el-button @click="save" type="primary" plain size="mini">保存</el-button>
		<!-- <el-button plain @click="initDb" size="mini" type="primary">下发数据库</el-button> -->
		<!-- <el-button plain @click="publish('Right')" size="mini" type="primary">初始化用户权限</el-button> -->
		<!-- <el-button plain @click="publish('Set')" size="mini" type="primary">发布设置</el-button> -->
		<!-- <el-button plain @click="publish('Fun')" size="mini" type="primary">发布接口</el-button> -->
		<!-- <el-button plain @click="publish('Ui')" size="mini" type="primary">发布页面</el-button> -->
		<!-- <el-button plain @click="publish('All')" size="mini" type="primary">全部发布</el-button> -->
	</el-tab-pane>
	<el-tab-pane label="后台" name="后台">
		<el-descriptions title="小程序" :column="1" border>
		  <el-descriptions-item label="AppId">
			  <input class="ipt" v-model="sysConfig.appId"></input>
		  </el-descriptions-item>
		  <el-descriptions-item label="密钥">
			  <input class="ipt" v-model="sysConfig.AppSecre"></input>
		  </el-descriptions-item>
		</el-descriptions>
		<el-descriptions title="微信支付" :column="1" border>
		  <el-descriptions-item label="商户号(mchId)">
			  <input class="ipt" v-model="sysConfig.mchId"></input>
		  </el-descriptions-item>
		  <el-descriptions-item label="密钥V3">
			  <input class="ipt" v-model="sysConfig.apiV3Key"></input>
		  </el-descriptions-item>
		  <el-descriptions-item label="证书序列号(serial_no)">
			  <input class="ipt" v-model="sysConfig.serial_no"></input>
		  </el-descriptions-item>
		  <el-descriptions-item label="证书文件(.pem)">
			  <div style="display: inline-block;width: 200px;">{{sysConfig.mch_private_key?sysConfig.mch_private_key:'未上传'}}</div>
			  <el-button style="margin-left: 15px;" size="mini" @click="$refs.fileup.click()">上传</el-button>
			  <input ref="fileup" style="margin-left: 15px;display: none;" class="ipt" type="file" @change="fileChange"></input>
		  </el-descriptions-item>
		</el-descriptions>
		<el-button @click="saveConfig" type="primary" plain size="mini">保存</el-button>
	</el-tab-pane>
  </el-tabs>
</template>

<script>
export default {
  data () {
    return {
		active: '基础',
		model: {},
		bm: null,//{bgColor:'',enable: false,data:[]}, 
		cfg: {homePc: '',homeM: '',bm: {bgColor:'',enable: false,data:[]},prePage:{enable: false,type: 'text',text: '',img:'',video:''}},
		loading: null,
		sysConfig: {}
	}
  },
  created: function () {
    this.search()
	this.getSysConfig()
  },
  methods: {
    search () {
      const fun = { module: 'sys', action: 'getSet', prms: {sysId: this.$route.params.id}}
      this.common.request(fun, this.searchCb.bind(this))
    },
	searchCb(r){
		if (r.code == 0) {
			this.model = r.data[0][0]
			if(this.model.cfg) this.cfg = Object.assign(this.cfg,JSON.parse(this.model.cfg))
		} else this.$message(r.msg)
	},
	getSysConfig(){
		const fun = { module: 'RemoteFile', action: 'GetConfig', prms: {sysId: this.$route.params.id}}
		this.common.request(fun, this.getSysConfigCb.bind(this))
	},
	getSysConfigCb(r){
		this.sysConfig = r.data[0]
	},
	fileChange(e){
		let that = this
		try {
			var file = e.target.files[0]
			var reader = new FileReader();
			reader.onload = function () {
				that.sysConfig.mch_private_key = '就绪'
				that.sysConfig.mpk = this.result
			}
			reader.readAsText(file);
			e.srcElement.value = ""
			console.log(this.sysConfig)
		} catch (e) {
			alert('读取出错啦！' + e.toString())
		}
	},
    save () {
		// this.model.bm = JSON.stringify(this.bm)
		// this.cfg.sysId = this.$route.params.id
      const fun = { module: 'sys', action: 'updateSet', prms: {cfg: JSON.stringify(this.cfg),sysId: this.$route.params.id} }
      this.common.request(fun, this.saveCallback.bind(this))
    },
    saveCallback (r) {
      if (r.code == 0) this.$message({duration:600,type: "success",message:'保存成功'})
      else this.$message.error(r.msg)
    },
	saveConfig(){
		this.sysConfig.sysId = this.$route.params.id
		const fun = { module: 'RemoteFile', action: 'SetConfig', prms: this.sysConfig }
		this.common.request(fun, this.saveCallback.bind(this))
	},
    publish (type) {
      const fun = { module: 'RemoteRedis', action: 'Publish'+type, prms: {sysId: this.$route.params.id}}
      this.common.request(fun, this.publishCb.bind(this))
	  // this.loading = this.$loading({ lock: true, text: '正在发布。。。', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' })
    },
	publishCb(r){
		// this.loading.close()
		if (r.code == 0) this.$message({duration:600,message:'发布成功'})
		else this.$message.error(r.msg)
	},
	initDb(){
		this.$confirm('此操作将复制模板所有的表和权限数据, 是否继续?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' }).then(() => {
		  const fun = { module: 'RemoteDb',action:'InitFromTemplate', prms: { sysId: this.$route.params.id} }
		  this.common.request(fun, this.initCb.bind(this))
		})
		
	},
	initCb(r){
		if(r.code == 0) this.search()
		else this.$message.error(r.msg)
	},
    cancel () {
      this.$emit('cancel', '')
    }
  },
  props: {
    comData: {
      type: Object
    }
  },
  computed: {
    action () {
      if (this.comData.id) return 'update'
      else return 'add'
    }
  }
}
</script>

<style scoped>
  .ipt{
	  border: none;
	  width: 70vh;
  }
 
</style>
