<template>
  <div>
    <!-- <el-card>
      <img class="ctrlBtn" src="@/assets/align/left.png" @click="comSelection.alignLeft()" title="左对齐"/>
      <img class="ctrlBtn" src="@/assets/align/center.png" @click="comSelection.alignCenter()" title="横向居中对齐"/>
      <img class="ctrlBtn" src="@/assets/align/right.png" @click="comSelection.alignRight()" title="右对齐"/>
      <img class="ctrlBtn" src="@/assets/align/top.png" @click="comSelection.alignTop()" title="顶部对齐"/>
      <img class="ctrlBtn" src="@/assets/align/middle.png" @click="comSelection.alignMiddle()" title="垂直居中对齐"/>
      <img class="ctrlBtn" src="@/assets/align/bottom.png" @click="comSelection.alignBottom()" title="底部对齐"/>
      <img class="ctrlBtn" src="@/assets/align/sameHeight.png" @click="comSelection.sameHeight()" title="相同高度"/>
      <img class="ctrlBtn" src="@/assets/align/sameWidth.png" @click="comSelection.sameWidth()" title="相同宽度"/>
      <img class="ctrlBtn" src="@/assets/align/sameSize.png" @click="comSelection.sameSize()" title="相同大小"/>
      <img class="ctrlBtn" src="@/assets/align/del1.png" @click="comSelection.del()" title="删除"/>
    </el-card> -->
    <el-form v-if="current"  label-width="90px" size="mini">
        <el-form-item label="组件类型">
          <p style="margin-left: 10px; font-weight: bold;">{{current.name}}</p>
        </el-form-item>
        <el-form-item label="链接">
          <selectorPage v-model="current.link" :comData="comData"></selectorPage>
		  <selectorPage v-model="current.linkM" :comData="comData"></selectorPage>
        </el-form-item>
        <el-form-item v-for="dataFun in current.funs||[]" :label="dataFun.dataName">
          <selectorFun v-model="dataFun.fun" :comData="comData" placement="bottom-end"></selectorFun>
        </el-form-item>
        <el-form-item label="接口">
          <selectorFun v-model="current.fun" :comData="comData" placement="bottom-end"></selectorFun>
        </el-form-item>
        <el-form-item v-if="current.type=='simple'" label="数据源">
           <el-radio-group v-model="current.dataSource" size="mini">
            <el-radio-button label="set"></el-radio-button>
            <el-radio-button label="prop"></el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="current.dataSource=='prop'" label="数组序号">
          <el-input-number v-model="current.dataIndex"></el-input-number>
        </el-form-item>
		<el-form-item  label="样式">
			<jfStyle v-model="current.style"></jfStyle>
		</el-form-item>
		<el-form-item  label="JSON">
			<el-button size="mini" @click="jsonStr=JSON.stringify(current, null, 4);jsonVisible=true">JSON</el-button>
			<el-dialog title="JSON" :visible.sync="jsonVisible" top="5vh" :close-on-click-modal="false" width="70%" :append-to-body="true" @closed="updateJson">
				<el-input v-model="jsonStr" type="textarea" :rows="30"/>
			</el-dialog>
		</el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data () {
    return {
		jsonStr: '',
		jsonVisible: false,
      editors: [],
      key: 1
    }
  },
  created: function () {
  },
  methods: {
    callback (r) {
      if (r.code === 0) {
        const json = unescape(r.data[0][0].editorJson)
        console.log(json)
        const edts = JSON.parse(json)
        this.bindEditor(edts, this.current)
        this.editors = edts
      }
    },
    bindEditor (edts, com) {
      for (const edt of edts) {
        edt.com = com
        edt.key = this.key++
        if (edt.children.length > 0) this.bindEditor(edt.children, com[edt.name])
      }
    },
	updateJson (e) {
      try {
        const v = JSON.parse(this.jsonStr)
		// let keys = Object.keys(this.current)
		// for(let i = 0; i < keys.length; i++){
		// 	delete this.current[keys[i]]
		// }
        Object.assign(this.current, v)
      } catch (e) {
        this.$message.error('JSON错误--' + e.message)
      }
	  }
  },
  props: {
    current: Object,
    comSelection: Object,
    comData: Object
  }
}
</script>

<style scoped="scoped">
  
  .ctrlBtn{
    width: 24px;
    height: 24px;
  }
</style>
