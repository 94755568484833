<template>
  <div>
    <el-row>
        <el-button class="btn" plain size="mini" @click="newItem.visible = true" type="primary">新建</el-button>
        <el-button class="btn" plain size="mini" @click="search" type="primary">刷新</el-button>
        <el-button class="btn" plain size="mini" type="primary" @click="addQuery()">新建查询</el-button>
        <el-button class="btn" plain size="mini" type="primary" @click="showRedisData()">Redis</el-button>
    </el-row>
    <el-tree
      :data="tableList"
      node-key="id"
      :load="loadNode"
      lazy
      accordion>
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span>{{ data.Field }}</span>
        <span>
          <i v-if="node.level === 1" @click.stop="generateFun(data)">F</i>
          <i class="el-icon-search" v-if="node.level === 1" @click.stop="addQuery(data)"></i>
          <i class="el-icon-edit" v-if="node.level === 1" @click.stop="tblEdit(data)"></i>
          <i class="el-icon-delete" v-if="node.level === 1" @click.stop="tblDel(node,data)"></i>
        </span>
      </span>
    </el-tree>

    <el-dialog title="创建表" :visible.sync="newItem.visible" width="20%" @close="dgClose">
      <el-form>
        <el-form-item label="表名" label-width="80px">
          <el-input v-model="newItem.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注" label-width="80px">
          <el-input v-model="newItem.comment" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="newItem.visible = false">取 消</el-button>
        <el-button type="primary" @click="tblAdd">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      newItem: { name: '', comment: '', visible: false },
      tableList: []
    }
  },
  created: function () {
    this.search()
  },
  methods: {
    search () {
      const data = { module: 'RemoteDb',action:'GetTables', prms: { sysId: this.$route.params.id} }
      this.common.request(data, this.setTableData.bind(this))
    },
    setTableData (data) {
      this.tableList = this.getTableTree(data.data)
    },
    tblAdd (nodeData, action, instance) {
      const data = { module: 'RemoteDb', action: 'CreateTable', prms: { sysId: this.$route.params.id, name: this.newItem.name, comment: this.newItem.comment } }
      this.common.request(data, this.tblAddCallback.bind(this))
    },
    tblAddCallback (r) {
      if (r.code === 0) {
        this.search()
        this.newItem.visible = false
      } else this.$message.error(r.msg)
    },
    tblDel (node, nodeData) {
      this.$confirm('此操作将永久删除该表, 是否继续?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' }).then(() => {
        const data = { module: 'RemoteDb', action: 'DropTable', prms: { sysId: this.$route.params.id, tableName: nodeData.Field } }
        this.common.request(data, this.tblDelCallback.bind(this))
        this.$emit('del', nodeData)
      })
    },
    tblDelCallback (r) {
      if (r.code === 0) {
        this.search()
      } else this.$message.error(r.msg)
    },
    generateFun (nodeData) {
      this.$emit('generateFun', nodeData)
    },
    loadNode (node, resolve) {
      if (node.level === 0) {
        return resolve([{ name: 'region' }])
      }
      if (node.level > 1) return resolve([])
      const data = { module: 'RemoteDb', action: 'GetFields', prms: { sysId: this.$route.params.id, tableName: node.data.Field } }
      this.common.request(data, this.lazyCallback.bind(this, resolve))
    },
    lazyCallback (resolve, data) {
      resolve(data.data)
    },
    tblEdit (data) {
      this.$emit('edit', data)
    },
    addQuery (row) {
      const sql = row ? 'select * from ' + row.Field + ';' : ''
      this.$emit('search', sql)
    },
    showRedisData () {
      this.$emit('showRedisData')
    },
    getTableTree (data) {
      // let key = Object.keys(data[0])[0]
      const arr = []
      for (let j = 0; j < data.length; j++) {
        data[j].Field = data[j].Name
        // var node = {Field: data[j][key]}
        arr.push(data[j])
      }
      return arr
    },
    dgClose () {
      this.newItem.name = ''
      this.newItem.comment = ''
    }
  },
  props: {
    sysId: String
  }
}
</script>

<style scoped="scoped">
  .btn{
    margin-left: 1px;
    padding: 3px 3px;
  }
</style>
