<template>
	<div>
  <div>
    <el-row>
      <el-col :span="8">
        <el-button type="primary" size="mini" @click="add()">添加图片</el-button>
      </el-col>
      <el-col :span="2">
      背景颜色
      </el-col>
      <el-col :span="6">
        <el-color-picker v-model="com.bgColor"></el-color-picker>
      </el-col>
    </el-row>
    <el-table :data="com.data" stripe tooltip-effect="dark" style="width: 100%" :cellStyle="{padding:'0px'}">
      <el-table-column width="80" label="图片">
        <template slot-scope="scope">
          <selectorImg v-model="scope.row.imgUrl"></selectorImg>
        </template>
      </el-table-column>
      <el-table-column width="120" label="文本">
        <template slot-scope="scope">
          <el-input v-model="scope.row.text"></el-input>
        </template>
      </el-table-column>
      <el-table-column width="260" label="链接">
        <template slot-scope="scope">
          <selectorPage v-model="scope.row.link"></selectorPage>
        </template>
      </el-table-column>
      <el-table-column width="50" label="颜色">
        <template slot-scope="scope">
          <el-color-picker v-model="scope.row.color"></el-color-picker>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="70">
        <template slot-scope="scope">
          <div class="el-icon-top" @click="common.up(com.data,scope.$index)"></div>
          <div class="el-icon-bottom" @click="common.down(com.data,scope.$index)"></div>
          <div class="el-icon-close" @click="common.del(com.data,scope.$index)"></div>
        </template>
      </el-table-column>
    </el-table>
  </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  created: function () {
  },
  methods: {
    add () {
      if (this.com.data.length < 5) this.com.data.push({ text: '菜单', color: 'green', imgUrl: '', link: '' })
      else this.$message({ message: '底部菜单最多5个', type: 'warning' })
    },
	// create(){
	// 	let com = Object.assign({},this.com)
	// 	com.data.push({ text: '菜单', color: 'green', imgUrl: '', link: '' })
	// 	let fun = { module: 'ui', action: 'addBm', prms: { sysId: this.$route.params.id, type: '底部', name: '导航', json: escape(JSON.stringify(com)) } }
	// 	this.common.request(fun, this.createCb.bind(this))
	// },
	// createCb(r){
	// 	if(r.code == 0){
	// 		this.com.data.push({ text: '菜单', color: 'green', imgUrl: '', link: '' })
	// 	} else this.$message.error(r.msg)
	// }
  },
  props: {
    com: Object,
  }
}
</script>

