<template>
  <div>
    <el-button class="btn" plain size="mini" @click="addPage" type="primary">新建</el-button>
    <el-button class="btn" plain size="mini" @click="search" type="warning">刷新</el-button>
    <!-- <el-button class="btn" plain size="mini" @click="publish()" type="primary">全部发布</el-button> -->
    <el-tree
      :data="pageList"
      node-key="id"
      default-expand-all
      @node-click="treeNodeClick">
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span>{{ data.name }}</span>
        <span>
          <i v-if="node.isLeaf" class="el-icon-document-copy" @click.stop="copyPage(node, data)"></i>
          <i v-if="node.isLeaf || data.children.length === 0" class="el-icon-delete" @click.stop="del(node, data)"></i>
        </span>
      </span>
    </el-tree>

    <el-dialog :title="newItem.title" :visible.sync="newItem.visible" width="20%" @close="dgClose">
      <el-form>
        <el-form-item label="分类" label-width="80px">
          <el-input v-model="newItem.type" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="名称" label-width="80px">
          <el-input v-model="newItem.name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="newItem.visible = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  data () {
    return {
		loading: null,
      newItem: { title: '新建页面', name: '', type: '', visible: false },
      pageList: [],
    }
  },

  created: function () {
    this.search()
  },

  methods: {
    search () {
      let fun = { module: 'ui', action: 'getList', prms: { sysId: this.$route.params.id } }
      this.common.request(fun, this.setData.bind(this))
    },
    setData (r) {
      if (r.code === 0) {
        r = r.data[0]
        this.pageList = []
        for (var i = 0; i < r.length; i++) {
          r[i].ui = JSON.parse(unescape(r[i].json))
          const fil = this.pageList.filter(item => item.name === r[i].type)
          if (fil.length === 0) {
            const node = { name: r[i].type, children: [r[i]] }
            this.pageList.push(node)
          } else {
            fil[0].children.push(r[i])
          }
        }
      }
    },
    addPage () {
      this.newItem.title = '新建页面'
      this.newItem.visible = true
    },
    copyPage (node, nodeData) {
      this.newItem.title = '复制页面'
      this.newItem.visible = true
      this.newItem.fromId = nodeData.id
      this.newItem.type = nodeData.type
    },
    add () {
      const pageData = { coms: [] }
      const data = { module: 'ui', action: 'add', prms: { sysId: this.$route.params.id, type: this.newItem.type, name: this.newItem.name, json: escape(JSON.stringify(pageData)) } }
      if (this.newItem.title === '复制页面') {
        data.action = 'copy'
        data.prms.fromId = this.newItem.fromId
      }
      this.common.request(data, this.addCallback.bind(this))
    },
    addCallback (data) {
      if (data.code === 0) {
        this.search()
        this.newItem.visible = false
      } else this.$message.error(data.msg)
    },
    setColorByType (arr, id, type) {
      const other = type === 1 ? 4 : 1
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id === id) arr[i].type = type
        else if (arr[i].type !== other) arr[i].type = 0
        if (arr[i].children) this.setColorByType(arr[i].children, id, type)
      }
    },
    treeNodeClick (data, node) {
      if (node.isLeaf) {
        this.$emit('selected', data)
      }
    },
    del (node, nodeData) {
      this.$confirm('确定删除页面[ ' + nodeData.name + ' ]吗?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' }).then(() => {
        const data = { module: 'ui', action: 'delete', prms: { ids: nodeData.id } }
        this.common.request(data, this.delCb.bind(this))
        this.$emit('del', nodeData)
      }).catch(()=>{})
    },
    delCb (data) {
      if (data.code === 0) {
        this.search()
      }
    },
    publish () {
		this.loading = this.$loading({ lock: true, text: '正在发布', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' })
      const data = { module: 'RemoteRedis', action: 'PublishUi', prms: { sysId: this.$route.params.id } }
      this.common.request(data, this.publishCallback.bind(this))
    },
    publishCallback (data) {
		this.loading.close()
      if (data.code === 0) this.$message({ message: '发布成功！', type: 'success' })
      else this.$message.error(data.msg)
    },
    dgClose () {
      this.newItem.name = ''
      this.newItem.type = ''
    }
  },
  props: {
    sysId: String
  }
}
</script>

<style scoped="scoped">
  .btn{
    margin-left: 1px;
    padding: 3px 3px;
  }
  .custom-tree-node {
      -ms-flex: 1;
      flex: 1;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: justify;
      justify-content: space-between;
      font-size: 14px;
      padding-right: 8px;
  }
</style>
