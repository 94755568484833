<template>
  <el-container v-if="page">
    <!-- <el-aside :width="clientType === 'm'?'375px':'800px'"> -->
	<div class="fc" ref="fc"></div>
	<el-main>
    <!--中间------组件显示部分-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------->
    <el-row class="middleRow">
      <div style="height: 1000%;" :style="clientType === 'm'?'width: 375px':'width: 100%;transform-origin:0 0;transform: scale(1);'">
        <jfPanel :com.sync="page.ui" :current="current" :clientType="clientType"></jfPanel>
      </div>
    </el-row>
    <el-row class="mbt">
		<div style="width: 375px">
      <el-form label-width="50px" size="mini">
          <el-form-item class="pageCN" label="分类">
            <el-input v-model="page.type"></el-input>
          </el-form-item>
          <el-form-item class="pageCN" label="名称">
            <el-input v-model="page.name"></el-input>
          </el-form-item>
      </el-form>

       <el-radio-group v-model="clientType" size="mini">
        <el-radio-button label="m"></el-radio-button>
        <el-radio-button label="pc"></el-radio-button>
      </el-radio-group>
      <el-button v-if="page" @click="savePage" type="primary" style="width: 150px;" size="mini">保存</el-button>
	  </div>
    </el-row>
  <!--中间------组件显示部分-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------->
    <!-- </el-aside> -->
    </el-main>
	<el-aside width="4px">
	  <div class="spliter"  @mousedown="mousedown" ref="spliteRef"></div>
	</el-aside>
		<el-aside :width="rtWidth" ref="rtBar">
  <!--右侧------组件设置部分-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------->
    <el-tabs type="border-card" v-model="active">
      <el-tab-pane label="编辑" name="编辑" :lazy="true">
		  <currencyEditor :current.sync="currOne" :comSelection="current"></currencyEditor>
		  <component v-if="currOne" v-bind:is="currOne.name+'Editor'" :com.sync="currOne" :saveMethod="savePage"></component>
      </el-tab-pane>
      <el-tab-pane label="组件" name="组件" :lazy="true">
        <comsView :com.sync="page.ui" :current="current"/>
      </el-tab-pane>
      <el-tab-pane label="历史" name="历史" :lazy="true">
		  <el-button class="btn" plain size="mini" @click="getHistory" type="primary">刷新</el-button>
        <el-table ref="table" :height="'60vh'" :data="history" tooltip-effect="dark" row-key="id">
        	<el-table-column prop="ctime" label="时间" width="140"></el-table-column>
        	<el-table-column prop="name" label="创建人" width="65"></el-table-column>
        	<el-table-column label="操作" width="60">
        		<template slot-scope="scope">
        			<el-link type="success" size="mini" @click.stop="revert(scope.row)">还原</el-link>
        		</template>
        	</el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  <!--右侧------组件设置部分-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------->
</el-aside>
  </el-container>
</template>

<script>
import comSelection from '../utils/comSelection.js'
import grtEdit from '../utils/grtEdit.js'
import grtList from '../utils/grtList.js'

export default {
  data () {
    return {
		rtWidth: '300px',
		page: null,
      clientType: 'm',
      active: '编辑',
      current: new comSelection(),
      currOne: null,
	  bmStatus: 0,
	  history: []
    }
  },

  created: function () {
	  this.getData()
	  this.getHistory()
  },

  methods: {
	  getData(){
		  let fun = { module: 'ui', action: 'getById', prms: {id: this.comData.page.id} }
		  this.common.request(fun, this.getCb.bind(this))
	  },
	  getCb(r){
		  if(r.code == 0){
			  let page = r.data[0][0]
			  page.ui = JSON.parse(unescape(page.json))
			  this.page = page
		  } else this.$message.error(r.msg)
	  },
    savePage () {
      this.page.json = escape(JSON.stringify(this.page.ui))
	  this.page.uiId = this.page.id
      const data = { module: 'ui', action: 'update', prms: this.page }
      this.common.request(data, this.savePageCallback.bind(this))
    },
    savePageCallback (data) {
      if (data.code === 0) this.$message({ message: '页面保存成功！', type: 'success', showClose: true,offset:200 })
      else this.$message(data.msg)
    },
	saveBmCb(r){
		if (r.code === 0) this.$message({ message: '底部导航保存成功！', type: 'success', showClose: true,offset:200 })
		else this.$message(r.msg)
	},
	getHistory(){
		this.history = []
		let fun = { module: 'ui', action: 'getHistory', prms: { uiId: this.comData.page.id, sysId: this.$route.params.id } }
		this.common.request(fun, this.historyCb.bind(this))
	},
	historyCb(r){
		if(r.code == 0) {
			this.history = r.data[0]
		} else {
			this.$message.error(r.msg)
		}
	},
	revert(history){
		let fun = { module: 'ui', action: 'revert', prms: { hId: history.id,id:history.uiId,sysId: this.$route.params.id } }
		this.common.request(fun, this.revertCb.bind(this))
	},
	revertCb(r){
		if(r.code == 0){
			this.getData()
			
		} else {
			this.$message.error(r.msg)
		}
	},
    generate () {
      const fileName = this.page.name + '.vue'
      const ctrl = this.page.ui.coms[0]
      if (ctrl.name === 'pcEdit') {
        let html = grtEdit.getHtml(ctrl.fields)
        html += grtEdit.getJs(ctrl)
        html += grtEdit.getCss()
        this.common.downTxt(fileName, html)
      } else if (ctrl.name === 'pcList') {
        const html = grtList.getHtml(ctrl)
        this.common.downTxt(fileName, html)
      } else {
        this.common.downTxt(fileName, ctrl.name)
      }
    },
	mousedown(evt) {
	  let sourceDom = this.$refs.rtBar;
	  let that = this
	  let width = parseInt(sourceDom.width)
	  let pageX = evt.pageX
	  let start = true
	  this.$refs.fc.style.display = 'block'
	  document.onmousemove = (event) => {
		if(start) {
			that.rtWidth = sourceDom.width = width - (event.pageX - pageX) + "px";
		}
	  };
	  document.onmouseup = () => {
		  this.$refs.fc.style.display = 'none'
		document.onmousemove = null;
		document.onmouseup = null;
		start = false
		return false;
	  }
	}
  },
  watch: {
    'current.selection': function () {
      if (this.current.selection.length !== 1) this.currOne = null
      else this.currOne = this.current.coms[this.current.selection[0]]
    }
  },
  props: {
    comData: Object
  }
}
</script>
<style  lang='scss' scoped>
.h100 {height: 100%;}
.middleRow{
  height: calc(100% - 185px);
  max-height:564px;
  overflow: auto;
  display: flex;
  justify-content: center;
}
.middleRowH{
  background-color: #999999;
  height: calc(100% - 185px);
  max-height:500px;
  overflow: auto;
}
.mbt{
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}
.el-tabs{
  width:99%;
  height: calc(100% - 2px);
}

.com{
  height: 100%;
  overflow: auto;
}
.middleRow::-webkit-scrollbar { width: 0 !important }
.el-form-item{margin: 0;}

.editor{
  overflow-y:scroll;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.editor{
  overflow-y:auto;
}
.el-tag{
  cursor: pointer;
}
.el-main{
  padding: 0;
  margin-left: 5px;
  padding-left: 5px;
  /* border-left: 1px solid #B3C0D1; */
}
.currentCom{
  border-width: 2px;
  border-style: dashed;
  border-color: red;
  background-color: white;
  position: relative;
}
.nomalItem{
  border-width: 2px;
  border-style: solid;
  border-color: Gainsboro;
  background-color: white;
}
.pageCN{width: 100%; display: inline-block;}
.pageRight{display: inline;}
.dataBind{width: 160px; display: inline-block;}
.el-input--mini .el-input__inner{padding: 0;}
.spliter{
    height:100%;
    background-color: #006666;
	cursor: e-resize;
  }
  .fc{
  	  display: none;
  	  position: fixed;
  	  top: 0;
  	  left: 0;
  	  width: 100%;
  	  height: 100%;
  	  background-color: rgba(216, 216, 216, 0.1);
  	  z-index: 100;
  }
</style>
