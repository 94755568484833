<template>
  <el-tabs type="border-card" v-model="active" @tab-click="tabClick">
    <el-tab-pane label="编辑" name="编辑">
      <el-form label-width="100px" class="demo-ruleForm" size="mini">
        <el-row>
          <el-col :span="10">
            <el-form-item label="名称">
              <el-input v-model="info.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="权限">
			  <selectorRight v-model="info.rightId"></selectorRight>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-button plain size="mini" @click="save()" type="primary">保存</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="模块">
              <el-input v-model="info.module"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="操作">
              <el-input v-model="info.action"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="接口类型">
              <el-radio-group v-model="info.funType">
                  <el-radio label="SQL">SQL</el-radio>
                  <el-radio label="API">API</el-radio>
                </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="操作类型">
              <el-radio-group v-model="info.actionType">
                  <el-radio label="Read">Read</el-radio>
                  <el-radio label="Write">Write</el-radio>
                </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-button plain size="mini" v-if="info.actionType == 'Read' && info.rightId>0" @click="saveField" type="primary">生成字段权限</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="后置程序" style="width: 100%;">
              <el-input v-model="info.after" placeholder="格式例如:module-action,module-action"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注" style="width: 100%;">
              <el-input v-model="info.memo" type="textarea" :rows="3"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-input @blur="cursorPosition = $event.srcElement.selectionStart" v-model="info.text" type="textarea" :rows="10"></el-input>
          </el-col>
        </el-row>
      </el-form>
		<el-row>
			<el-col :span="14">
				<el-table :data="define.in" stripe tooltip-effect="dark" style="width: 100%" :cellStyle="{padding:'0px'}">
				<el-table-column label="输入参数" align="center">
				<el-table-column label="" width="60">
					<template slot-scope="scope">
						<div class="el-icon-close" @click="define.in.splice(scope.$index, 1)"></div>
					</template>
				</el-table-column>
				<el-table-column label="字段" width="100">
					<template slot-scope="scope">
						<input v-model="scope.row.field"/>
					</template>
				</el-table-column>
				<el-table-column label="中文" width="120">
					<template slot-scope="scope">
						<input v-model="scope.row.comment"/>
					</template>
				</el-table-column>
				<el-table-column label="类型" width="150">
					<template slot-scope="scope">
						<el-autocomplete class="inline-input" v-model="scope.row.type" :fetch-suggestions="querySearch"></el-autocomplete>
					</template>
				</el-table-column>
				<el-table-column label="长度" width="80">
					<template slot-scope="scope">
						<input v-model="scope.row.len"/>
					</template>
				</el-table-column>
				<el-table-column label="必填" width="50" align="center">
					<template slot-scope="scope">
						<el-checkbox v-model="scope.row.required"></el-checkbox>
					</template>
				</el-table-column>
				</el-table-column>
			</el-table>
			<el-button size="mini" @click="addPrms('in')">添加</el-button>
			</el-col>
			<el-col :span="10">
				<el-table :data="define.out" stripe tooltip-effect="dark" style="width: 100%" :cellStyle="{padding:'0px'}">
				<el-table-column label="返回信息" align="center">
				<el-table-column label="" width="60">
					<template slot-scope="scope">
						<div class="el-icon-close" @click="define.out.splice(scope.$index, 1)"></div>
					</template>
				</el-table-column>
				<el-table-column label="字段" width="100">
					<template slot-scope="scope">
						<input v-model="scope.row.field"/>
					</template>
				</el-table-column>
				<el-table-column label="中文" width="120">
					<template slot-scope="scope">
						<input v-model="scope.row.comment"/>
					</template>
				</el-table-column>
				<el-table-column label="类型" width="150">
					<template slot-scope="scope">
						<el-autocomplete class="inline-input" v-model="scope.row.type" :fetch-suggestions="querySearch"></el-autocomplete>
					</template>
				</el-table-column>
				</el-table-column>
			</el-table>
			<el-button size="mini" @click="addPrms('out')">添加</el-button>
			</el-col>
		</el-row>
		
		
    </el-tab-pane>
    <el-tab-pane label="测试" name="测试" :lazy="true">
      <el-col>
          <el-radio-group v-model="hasToken">
            <el-radio :label="0">不带</el-radio>
            <el-radio :label="1">带Token</el-radio>
          </el-radio-group>
          <el-row v-for="(value, key, index) in testPrms" :key="index">
            <el-col :span="8">{{key}}</el-col>
            <el-col :span="8"><el-input v-model="testPrms[key]" placeholder="Token"></el-input></el-col>
          </el-row>
          <el-button plain size="mini" @click="test()" type="primary">提交</el-button>
      </el-col>
      <el-col>
        <el-input v-model="currentToken" placeholder="Token" v-if="hasToken===1"></el-input>
          <el-table :data="users" stripe style="width: 100%;height: 500px;overflow: auto;" v-if="false">
            <el-table-column prop="name" label="账号" width="120"></el-table-column>
            <el-table-column prop="roles" label="角色" width="150"></el-table-column>
            <el-table-column label="Token">
              <template slot-scope="scope">
                <el-link size="mini" plain type="primary" @click="currentToken = scope.row.token">使用</el-link>
              </template>
            </el-table-column>
          </el-table>
      </el-col>
    </el-tab-pane>
    <el-tab-pane label="测试结果" name="测试结果" :lazy="true">
		<jfJsonView v-if="testResult" :json="testResult"></jfJsonView>
    </el-tab-pane>
    <el-tab-pane label="日志" name="日志" :lazy="true">
      <el-button plain size="mini" @click="getLogs" type="primary">获取</el-button>
      <el-button plain size="mini" @click="clearLogs" type="primary">清除</el-button>
      <el-table :data="logs" stripe style="width: 100%" >
        <el-table-column prop="ctime" label="时间" width="100"></el-table-column>
        <el-table-column prop="msg" label="日志"></el-table-column>
      </el-table>
    </el-tab-pane>
	<el-tab-pane label="历史" name="历史" :lazy="true">
		<el-button class="btn" plain size="mini" @click="getHistory" type="primary">刷新</el-button>
	  <el-table ref="table" :height="'60vh'" :data="history" tooltip-effect="dark" row-key="id">
	  	<el-table-column prop="ctime" label="时间" width="140"></el-table-column>
	  	<el-table-column prop="name" label="创建人" width="65"></el-table-column>
	  	<el-table-column label="操作" width="60">
	  		<template slot-scope="scope">
	  			<el-link type="success" size="mini" @click.stop="revert(scope.row)">还原</el-link>
	  		</template>
	  	</el-table-column>
	  </el-table>
	</el-tab-pane>
  </el-tabs>
</template>
<script>
export default {
  data () {
    return {
      active: '编辑',
      info: {},
      users: [{ account: 'fffffff' }],
      hasToken: 0,
      currentToken: '',
      testPrms: {},
      testResult: null,
      logs: [],
      rights: [],
      selection: [],
	  define: {out:[],in:[]},
	  cursorPosition: 0,
	  history: []
    }
  },
  created: function () {
	this.getData(true)
	this.currentToken = localStorage.getItem('testToken') || ''
  },
  methods: {
	  getData(first){
		  let fun = { module: 'sysfun', action: 'getById', prms: { id: this.comData.fun.id } }
		  if(!this.comData.fun.id) fun = { module: 'sysfun', action: 'getByName', prms: 
		  { module: this.comData.fun.module,action:this.comData.fun.action,sysId:this.$route.params.id } }
		  this.common.request(fun, this.getFunCb.bind(this,first))
	  },
	  getFunCb (first,r) {
	    if (r.code === 0) {
	      this.info = r.data[0][0]
		  if(this.info.define) this.define = JSON.parse(this.info.define)
		  if(first) this.getHistory()
	    } else this.$message.error(r.msg)
	  },
    getUsersCallback (r) {
      if (r.code === 0) {
        this.users = r.data
      } else this.$message.error(r.msg)
    },
    save () {
		this.info.define = JSON.stringify(this.define)
		this.info.funId = this.info.id
		this.info.sysId=this.$route.params.id
      const fun = { module: 'sysfun', action: 'update', prms: this.info }
      this.common.request(fun, this.saveCallback.bind(this))
    },
    saveCallback (r) {
      if (r.code === 0) {
		  if(this.comData.fun.id) this.$message({ message: '保存成功！', type: 'success', showClose: true,offset:200 })
			else {
				this.$emit('funSaved',this.info)
			}
	  } else this.$message.error(r.msg)
    },
    getLogs () {
      const data = { module: 'RemoteDb', action: 'GetLogs', prms: {ctype: this.info.module + '-' + this.info.action } }
      data.prms.sysId = this.$route.params.id
      this.common.request(data, this.getLogsCallback.bind(this))
    },
    clearLogs () {
      const data = { module: 'RemoteDb', action: 'ClearLogs', prms: {ctype: this.info.module + '-' + this.info.action } }
      data.prms.sysId = this.$route.params.id
      this.common.request(data, this.getLogsCallback.bind(this))
    },
    getLogsCallback (r) {
      if (r.code === 0) this.logs = r.data
      else this.$message.error(r.msg)
    },
    test (type) {debugger
		if(this.currentToken.length>0) localStorage.setItem('testToken', this.currentToken)
      const fun = { module: this.info.module, action: this.info.action, prms: this.testPrms, devMode: 1 }
      const opt = { server: this.$route.params.url, subsys: this.$route.params.subsys,file:'interface' }
      if (this.hasToken) opt.token = this.currentToken
      else opt.withToken = false
	  if(type == 1) fun.devMode = 2
      this.common.request(fun, this.callback.bind(this), opt)
    },
    callback (r) {
		this.testResult = r // JSON.stringify(data, undefined, 4)
		this.active = '测试结果'
    },
	saveField(){
		let obj = {}
		for(let i = 0; i < this.define.out.length; i++){
			obj[this.define.out[i].field] = this.define.out[i].comment.replace(/\r\n/g,'')
		}
		let fun = { module: 'RemoteDb', action: 'SaveField', 
		prms: {sysId: this.$route.params.id, rightId: this.info.rightId, fields: JSON.stringify(obj) } }
		this.common.request(fun, this.saveFieldCb.bind(this))
	},
	saveFieldCb(r){
		if(r.code == 0) this.$message.success('保存成功')
		else this.$message.error(r.msg)
	},
    tabClick (tab) {
      if (tab.label === '测试') this.setPrms()
    },
	addPrms(type){
		if(type == 'in') this.define.in.push({field:'',comment:'',type:'',len:0,require:true})
		if(type == 'out') this.define.out.push({field:'',comment:'',type:''})
	},
	setPrms(){
		if (this.info.text) {
		  const prms = {}
		  const reg = /:\w+/g
		  const arr = this.info.text.match(reg)
		  for (let i = 0; arr && i < arr.length; i++) {
		    const key = arr[i].substring(1)
			let has = this.define.in.find(p=>p.field == key)
			if(!has) this.define.in.push({field:key,comment:'',type:'',len:0,require: true})
		    if (this.testPrms[key]) prms[key] = this.testPrms[key]
		    else prms[key] = ''
		  }
		  this.testPrms = prms
		  if(this.info.funType == "SQL" && this.info.actionType == "Read"){
			  let end = this.info.text.indexOf('from')
			  let sub = this.info.text.substring(0,end).trim()
			  if(sub.indexOf('select') == 0) sub = sub.substr(6,sub.length)
			  sub = sub.replace('SQL_CALC_FOUND_ROWS','')
			  let flds = sub.split(',')
			  for(let i = 0; i < flds.length; i++){
				  let fld = flds[i].trim().replace(/`/g,'')
				  let has = this.define.out.find(p=>p.field == fld)
				  if(!has) this.define.out.push({field:fld,comment:'',type:''})
			  }
		  }
		}
	},
    selectionChange (val) {
      this.selection = val
    },
	insertOrderBy(){
		if(this.comData.cols.length == 0) return
		let cols = ''
		for(let i = 0; i < this.comData.cols.length; i++){
			if(this.comData.cols[i].sortable) cols+=this.comData.cols[i].field+','
		}
		cols = ' ORDER BY {:orderBy|'+cols.slice(0, -1)+'} '
		this.info.text = this.info.text.slice(0,this.cursorPosition) + cols + this.info.text.slice(this.cursorPosition)
	},
	querySearch(queryString, cb) {
		let restaurants = [{value:'int'},{value:'varchar'},{value:'date'},{value:'time'},{value:'datetime'},{value:'decimal'}]
		// let restaurants = [{value:'string'},{value:'phone'},{value:'email'},{value:'pwd'},{value:'int'},{value:'number'},{value:'time'},{value:'date'},{value:'datetime'},{value:'url'},{value:'idNumber'},{value:'ip'}]
		var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
		cb(results);
	},
	createFilter(queryString) { 
		return (restaurant) => {
			return (queryString != restaurant.value) && (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
		};
	},
    deleteGo (arr, index) {
      arr.splice(index, 1)
    },
	getHistory(){
		this.history = []
		let fun = { module: 'sysfun', action: 'getHistory', prms: { funId: this.info.id, sysId: this.$route.params.id } }
		this.common.request(fun, this.historyCb.bind(this))
	},
	historyCb(r){
		if(r.code == 0) {
			this.history = r.data[0]
		} else {
			this.$message.error(r.msg)
		}
	},
	revert(history){
		let fun = { module: 'sysfun', action: 'revert', prms: { hId: history.id,id:history.funId,sysId: this.$route.params.id } }
		this.common.request(fun, this.revertCb.bind(this))
	},
	revertCb(r){
		if(r.code == 0){
			this.getData(false)
			this.active = '编辑'
			
		} else {
			this.$message.error(r.msg)
		}
	}
  },
  props: {
    comData: Object
  }
}
</script>
<style>
  .el-form-item--mini.el-form-item{margin-bottom: 0px;}
  .el-form-item__label{text-align: left;background-color: #ecf5ff;}
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }
  ..el-tree{background-color: #ecf5ff;}
  .el-tree-node__content{height: 30px;}
  .flds{
	  min-height: 200px;
	  border: 1px solid darkgray;
	  display: flex;
	  align-content: flex-start;
	  flex-wrap: wrap;
	  box-sizing: border-box;
	  gap:10px;
	  background-color: bisque;
  }
  .fldsItem{
	  width: 250px;
	  height: 30px;
	  background-color: aliceblue;
	  padding: 5px;
  }
  .fldsItem input{
	  height: 30px;
	  display: inline;
	  width: 48%;
	  background-color: white;
  }
</style>
