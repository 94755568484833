<template>
  <el-form label-width="100px" size="mini">
    <el-form-item label="名称">
      <el-input v-model="comData.com.cname"></el-input>
    </el-form-item>
    <el-form-item label="分类">
      <el-input v-model="comData.com.type"></el-input>
    </el-form-item>
    <el-form-item label="图标">
      <el-input v-model="comData.com.icon"></el-input>
    </el-form-item>
    <el-form-item label="json">
      <el-input type="textarea" :rows="25" v-model="jsonStr" @change="setEditor"></el-input>
    </el-form-item>
    <el-form-item label="">
      <el-button plain size="mini" @click="save()" type="primary">保存</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
export default {
  data () {
    return {
      jsonStr: '',
      proptys: []
    }
  },
  created: function () {
    const jsonObj = JSON.parse(unescape(this.comData.com.json))
    this.jsonStr = JSON.stringify(jsonObj, null, 4)
    if (this.comData.com.editorJson && this.comData.com.editorJson !== 'undefined') {
      this.proptys = JSON.parse(unescape(this.comData.com.editorJson))
    } else this.proptys = this.getTree(jsonObj)
  },
  methods: {
    save () {
      let com
      try {
        com = JSON.parse(this.jsonStr)
      } catch (e) {
        this.$message.error('JSON错误--' + e.message)
        return
      }
      this.comData.com.json = escape(JSON.stringify(com))
      this.comData.com.editorJson = escape(JSON.stringify(this.proptys))
      const data = { module: 'com', action: 'update', prms: this.comData.com }
      this.common.request(data, this.saveCallback.bind(this))
      console.log(this.proptys)
    },
    saveCallback (data) {
      if (data.code === 0) {
        this.$message({ message: '保存成功！', type: 'success' })
      } else this.$message.error(data.msg)
    },
    setEditor () {
      const jsonObj = JSON.parse(this.jsonStr)
      const newArr = this.getTree(jsonObj)
      this.mergeTree(newArr, this.proptys)
      this.proptys = newArr
    },
    mergeTree (newArr, oldArr) {
      for (let i = 0; i < newArr.length; i++) {
        const fil = oldArr.filter(item => item.name === newArr[i].name)
        if (fil.length > 0) {
          newArr[i].label = fil[0].label
          newArr[i].editor = fil[0].editor
          newArr[i].options = fil[0].options
          if (newArr[i].children.length > 0 && fil[0].children.length > 0) { this.mergeTree(newArr[i].children, fil[0].children) }
        }
      }
    },
    getTree (obj) {
      const arr = []
      for (const p in obj) {
        if (p === 'name') continue
        const node = { name: p, editor: 'el-input', options: '', children: [] }
        const isObj = Object.prototype.toString.call(obj[p]) === '[object Object]'
        if (isObj) {
          node.children = this.getTree(obj[p])
          if (node.children.length > 0) node.hasChildren = true
        }
        arr.push(node)
      }
      return arr
    }
  },
  props: {
    comData: Object
  }
}
</script>
<style scoped="scoped">
.el-form-item {
    width: 100%;
}
</style>
