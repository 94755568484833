import $ from 'jquery'
import axios from 'axios'
export default {
	version: '1.02',
	tableHeight: window.innerHeight - 80, 
  $: $,
  serverUrl: config.serverUrl,
  // serverUrl:'https://www.bjapp11.com/jfql/',
  request (pram, callback, option) {
    const opt = {
      server: this.serverUrl,
      subsys: 'jfql',
      files: [],
      withToken: true,
      token: localStorage.getItem('jfDevToken'),
      file: config.interface
    }
	
    Object.assign(opt, option)
    if (opt.withToken) pram.token = opt.token
    pram.subsys = opt.subsys
	
	let formData = new FormData()
	for (let i = 0; i < opt.files.length; i++) {
	  formData.append('files' + i, opt.files[i])
	}
	formData.append('data', JSON.stringify(pram))
	// axios.post(opt.server + opt.file,formData).then(function (r) {
	// 	if(r.status == 200) {
	// 	r = r.data
 //        if ((r.code === 2 || r.code === 3 || r.code === 7) && (pram.subsys == 'jfql')) {
 //          this.router.push({ path: '/login' }, () => {}, () => {})
 //        } else if (callback) callback(r)
	// 	} else {console.log(r)}
 //      }.bind(this));
	// return
	
    $.ajax({
      url: opt.server + opt.file,
      type: 'post',
      dataType: 'json',
      crossDomain: true,
      xhrFields: { withCredentials: true },
      data: formData,
      contentType: false,
      processData: false,
      success: function (r) {
        if ((r.code === 2 || r.code === 3 || r.code === 7) && (pram.subsys == 'jfql')) {
          this.router.push({ path: '/login' }, () => {}, () => {})
        } else if (callback) callback(r)
      }.bind(this),
      error: function (r) {
        console.log(r)
      }
    })
    delete pram.token
  },
  imgOnError : 'this.src="' + require('@/assets/img/default.png') + '"',
  getImgUrl (url) {
    if (url === null || url === '' || url === 'undefined') return require('@/assets/img/default.png')
    else if (url.indexOf('://') == -1) return this.serverUrl + url
    else return url
  },
  imgError (e) {
    e.target.src = require('@/assets/img/default.png')
  },
  up (arr, index) {
    if (index !== 0) {
      arr[index] = arr.splice(index - 1, 1, arr[index])[0]
    } else {
      arr.push(arr.shift())
    }
  },
  down (arr, index) {
    if (index !== arr.length - 1) {
      arr[index] = arr.splice(index + 1, 1, arr[index])[0]
    } else {
      arr.unshift(arr.splice(index, 1)[0])
    }
  },
  del (arr, index) {
    arr.splice(index, 1)
  },
  getProperty (obj, path) {
    const arr = path.split('.')
    let sub = obj
    for (let i = 0; i < arr.length; i++) {
      sub = sub[arr[i]]
    }
    return sub
  },
  downTxt (name, data) {
    const urlObject = window.URL || window.webkitURL || window
    const export_blob = new Blob([data])
    const save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a')
    save_link.href = urlObject.createObjectURL(export_blob)
    save_link.download = name
    const ev = document.createEvent('MouseEvents')
    ev.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
    save_link.dispatchEvent(ev)
  },
	loadScript(url, callback) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      // 处理IE
      if (script.readyState) {
        script.onreadystatechange = function () {
          if (script.readyState === 'loaded' || script.readyState === 'complete') {
            script.onreadystatechange = null;
            callback();
          }
        }
      } else {
        // 处理其他浏览器的情况
        script.onload = function () {
          callback();
        }
      }
      script.src = url;
      document.body.append(script);
    }
}
