<template>
	<div>
	<el-popover width="300" trigger="click" @show="onShow" @hide="onHide">
	    <el-form label-width="70px" size="mini">
	      <el-form-item label="左边距">
	        <el-input v-model="comStyle.left"></el-input>
	      </el-form-item>
	      <el-form-item label="右边距">
	        <el-input v-model="comStyle.right"></el-input>
	      </el-form-item>
	      <el-form-item label="宽度">
	        <el-input v-model="comStyle.width"></el-input>
	      </el-form-item>
	      <el-form-item label="高度">
	        <el-input v-model="comStyle.height"></el-input>
	      </el-form-item>
	      <el-form-item label="圆角">
	        <el-input v-model="comStyle['border-radius']"></el-input>
	      </el-form-item>
	      <el-form-item label="fontSize">
	        <el-input v-model="comStyle['font-size']"></el-input>
	      </el-form-item>
	      <el-form-item label="float">
	        <el-radio-group v-model="comStyle.float">
	            <el-radio :label="''">取消</el-radio>
	            <el-radio :label="'left'">left</el-radio>
	            <el-radio :label="'right'">right</el-radio>
	          </el-radio-group>
	      </el-form-item>
	      <el-form-item label="border">
	        <el-input v-model="comStyle.border"></el-input>
	      </el-form-item>
	      <el-form-item label="margin">
	        <el-input v-model="comStyle.margin"></el-input>
	      </el-form-item>
	      <el-form-item label="padding">
	        <el-input v-model="comStyle.padding"></el-input>
	      </el-form-item>
	      <el-form-item label="颜色">
	        <span style="padding:20px 0;">字体</span>
	        <el-color-picker v-model="comStyle['color']"></el-color-picker>
	        <span style="padding:20px 0;">背景</span>
	        <el-color-picker v-model="comStyle['background-color']"></el-color-picker>
	      </el-form-item>
	    </el-form>
	  <el-button type="primary" size="mini" slot="reference">样式</el-button>
	</el-popover>
	</div>
</template>

<script>
export default {
  data () {
	  return{
		  comStyle:{}
	  }
  },
  created() {
  },
  methods: {
	  onShow(){
		  if(this.propStyle) this.comStyle = this.propStyle
		  console.log(this.comStyle)
	  },
	  onHide(){
		  // let keys = Object.keys(this.propStyle)
		  // let stl = {}
		  // for(let key in keys){
			 //  if(this.propStyle[key] != null && this.propStyle[key] != '') stl[key] = this.propStyle[key]
		  // }
		  this.$emit('setStyle', Object.assign({},this.comStyle))
	  }
  },
  model: {
    prop: 'propStyle',
    event: 'setStyle'
  },
  props: {
    propStyle: Object
  }
}
</script>

<style>
</style>